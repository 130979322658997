import React from 'react'
import { navigate, graphql } from 'gatsby'

import { isLoggedIn, LoginPath } from '../services/auth'

const Lucky = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  if (typeof window === 'undefined') return null
  if (!isLoggedIn(window)) {
    navigate(LoginPath)
    return null
  }

  const Posts = edges
    .filter(edge => edge.node.frontmatter.path.includes('lucky'))
    .map(edge => <h2 key={edge.node.id}>{edge.node.frontmatter.title}</h2>)

  if (Posts.length === 1) {
    navigate(edges[0].node.frontmatter.path)
  }

  return <div>{Posts}</div>
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            fakeURL
            redirect
            description
          }
        }
      }
    }
  }
`

export default Lucky
